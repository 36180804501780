import React, { useEffect } from "react";
import NoSleep from "nosleep.js";

interface Props {
  setPreventSleepStatus: (wakelockStatus: boolean) => void;
}

export const DisableSleep: React.FunctionComponent<Props> = ({
  setPreventSleepStatus,
}) => {
  useEffect(() => {
    const noSleep = new NoSleep();
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable().then((r) => setPreventSleepStatus(noSleep.isEnabled));
      },
      false
    );

    return () => {
      noSleep.disable();
      setPreventSleepStatus(noSleep.isEnabled);
    };
  }, []);

  return null;
};
