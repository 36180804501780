import { isMobile } from "react-device-detect";
import React from "react";

interface Props {
  preventSleepStatus: boolean;
}

export const Alert: React.FunctionComponent<Props> = ({
  preventSleepStatus,
}) => {
  return isMobile && !preventSleepStatus ? (
    <div
      className={`rounded bg-yellow-50 p-4 absolute m-auto left-2 right-2 h-36 flex place-items-center text-center justify-center`}
    >
      <div className="flex">
        <div className="">
          <h3 className="text-sm font-medium text-yellow-800">Achtung!</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{`Bildschirm schaltet ab!`}</p>
            <p>{`Bitte einmal auf die Website tippen`}</p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
