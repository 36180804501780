import React, { useState, useEffect } from "react";
import { Phase } from "./components/Phase";
import { useUrlSearchParams } from "use-url-search-params";
import { DisableSleep } from "./components/DisableSleep";
import { Alert } from "./components/Alert";

const initialMethods = [
  "",
  "Tweets über den Sprint",
  "Was zieht, was bremst?",
  "Kommentarblasen",
  "Drei-Punkte-Wahl",
  "Wunschkonzert",
];

const phaseName = [
  "Intro",
  "Set the Stage",
  "Gather Data",
  "Generate Insights",
  "Decide what to do",
  "Closing the Retrospective",
];

const phaseLengthPresets = [
  [5, 5, 10, 10, 10, 5], // 45 min
  [5, 5, 15, 15, 15, 5], // 60 min
  [5, 10, 15, 20, 20, 5], // 75 min
  [10, 10, 20, 20, 20, 10], // 90 min
  [10, 15, 25, 30, 30, 10], // 120 min
];

const initialPhaseLengths = phaseLengthPresets[3];

function App() {
  const initialParams = {
    methods: JSON.stringify(initialMethods),
    phaseLengths: JSON.stringify(initialPhaseLengths),
  };

  const [preventSleepStatus, setPreventSleepStatus] = useState(false);

  const handleSleepStatus = (wakelockStatus: boolean) => {
    setPreventSleepStatus(wakelockStatus);
  };

  // New States
  const [queries, setQueries] = useUrlSearchParams(initialParams);
  const startTimeHHMM =
    queries.startTimeHours && queries.startTimeMinutes
      ? queries.startTimeHours + ":" + queries.startTimeMinutes
      : "";
  const methods = JSON.parse(queries.methods?.toString() ?? "[]");
  const phaseLengths = JSON.parse(queries.phaseLengths?.toString() ?? "[]");
  const [currentTime, setCurrentTime] = useState(new Date().getTime()); // Date object mit der aktuellen Zeit
  const [notStarted, setNotStarted] = useState(!startTimeHHMM);
  const [editable, setEditable] = useState(false);

  const calcStartTime = () => {
    let today = new Date();
    today.setHours(Number(queries.startTimeHours));
    today.setMinutes(Number(queries.startTimeMinutes));
    today.setSeconds(0);
    today.setMilliseconds(0);

    return today.getTime();
  };

  const startTimeTimeStamp = calcStartTime();

  const convertMillisecondsToTimeFormat = (
    milliseconds: number,
    withoutHours: boolean
  ) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    if (!withoutHours) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
    if (withoutHours) {
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  };
  const handlePhaseLengthChange = (minutes: number, phase: number) => {
    let newState = [...phaseLengths];
    newState[phase] = minutes;
    setQueries({ phaseLengths: JSON.stringify(newState) });
    /*setPhaseLength((prevState) => {
      let newState = [...prevState];
      newState[phase] = minutes;
      return newState;
    });*/
  };

  const handleMethodRename = (method: string, phase: number) => {
    let newState = [...methods];
    newState[phase] = method;
    setQueries({ methods: JSON.stringify(newState) });
  };

  const handleEditClick = () => {
    setEditable(!editable);
  };

  const phaseLengthsMs = [
    phaseLengths[0] * 60 * 1000,
    phaseLengths[1] * 60 * 1000,
    phaseLengths[2] * 60 * 1000,
    phaseLengths[3] * 60 * 1000,
    phaseLengths[4] * 60 * 1000,
    phaseLengths[5] * 60 * 1000,
  ];

  const calcPhaseStartComponent = (neededStartComponent: number) => {
    let tempVar = 0;
    let tempArray = [];
    for (let x = 0; x < 7; x++) {
      tempArray.push(tempVar);
      tempVar = tempVar + phaseLengthsMs[x];
    }
    return tempArray[neededStartComponent];
  };

  const calcPhase = () => {
    for (let c = 0; c <= 5; c++) {
      if (
        startTimeTimeStamp &&
        currentTime <
          phaseLengthsMs[c] +
            (startTimeTimeStamp + calcPhaseStartComponent(c)) &&
        currentTime > startTimeTimeStamp + calcPhaseStartComponent(c)
      ) {
        return c;
      } else if (
        startTimeTimeStamp &&
        currentTime > startTimeTimeStamp + calcPhaseStartComponent(6)
      ) {
        return 6;
      }
    }
    return undefined;
  };
  const currentPhase = calcPhase();

  const calcTimeLeft = () => {
    if (!startTimeTimeStamp || startTimeTimeStamp > currentTime) {
      return phaseLengthsMs;
    }
    const timeElapsed = currentTime - startTimeTimeStamp;
    const timesLeft = [];
    for (let i = 0; i < 6; i++) {
      const timeElapsedSinceStartOfCurrentPhase = Math.max(
        timeElapsed - calcPhaseStartComponent(i),
        0
      );
      const timeElapsedInCurrentPhase = Math.min(
        timeElapsedSinceStartOfCurrentPhase,
        phaseLengthsMs[i]
      );
      timesLeft[i] = phaseLengthsMs[i] - timeElapsedInCurrentPhase;
    }
    return timesLeft;
  };
  const timeLeft = calcTimeLeft();

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  const onChangeStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setQueries({
      startTimeMinutes: event.target.value.split(":")[1],
      startTimeHours: event.target.value.split(":")[0],
    });
    setNotStarted(false);
  };

  return (
    <div>
      <DisableSleep setPreventSleepStatus={handleSleepStatus} />
      <div className="min-h-screen bg-gray-300">
        <header className="hidden sm:flex h-28 bg-gray-900 text-green-600 text-5xl sm:text-6xl justify-center items-center ">
          <p>Retrotimer</p>
        </header>
        <div>
          <div className="flex flex-col pb-2">
            <div className="flex justify-center items-center py-2 ">
              <p className="font-bold pr-2">Startzeit:</p>
              <input
                type="time"
                className="mt-1 w-20 h-6 text-center rounded"
                value={startTimeHHMM}
                onChange={onChangeStartTime}
                onFocus={(event) => event.target.select()}
              />
            </div>
            <div className="flex justify-center">
              <p className="font-bold pr-2">Endzeit:</p>
              <p className="displayEndTime">
                {notStarted
                  ? "00:00:00"
                  : new Date(
                      phaseLengthsMs[5] +
                        (startTimeTimeStamp + calcPhaseStartComponent(5))
                    )
                      .toLocaleTimeString()
                      .slice(0, -3)}
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full px-2 max-w-5xl mx-auto ">
            <div className="flex justify-end h-4 -mb-4 -translate-y-6 overflow-visible">
              <button className=" w-4" onClick={handleEditClick} tabIndex={-1}>
                <svg className="w-4 h-4 fill-current z-10" viewBox="0 0 20 20">
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                </svg>
              </button>
            </div>
            {editable && (
              <div className="mb-2">
                <span className="font-bold">
                  Presets:{" "}
                  {phaseLengthPresets.map((lengths, index) => (
                    <button
                      key={index}
                      className="mr-2"
                      onClick={() => {
                        setQueries({ phaseLengths: JSON.stringify(lengths) });
                      }}
                    >
                      {lengths.reduce((a, b) => a + b, 0)} min
                    </button>
                  ))}
                </span>
              </div>
            )}
            <Alert preventSleepStatus={preventSleepStatus} />
            {phaseLengthsMs.map((phaseLengthMs, index) => {
              return (
                <Phase
                  key={index}
                  phase={index}
                  phaseNumber={index}
                  timeLeft={timeLeft[index]}
                  phaseName={phaseName[index]}
                  phaseStartTime={
                    startTimeTimeStamp + calcPhaseStartComponent(index)
                  }
                  phaseEndTime={
                    phaseLengthMs +
                    (startTimeTimeStamp + calcPhaseStartComponent(index))
                  }
                  onChangeInputPhaseLength={handlePhaseLengthChange}
                  phaseMinutes={phaseLengths[index]}
                  notStarted={notStarted}
                  currentPhase={currentPhase}
                  msToTime={convertMillisecondsToTimeFormat}
                  phaseLengthMs={phaseLengthMs}
                  method={methods[index]}
                  editable={editable}
                  onChangeMethod={handleMethodRename}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
