import React, { useEffect } from "react";

interface Props {
  currentPhase: number | undefined;
  timeLeft: number;
  phaseLengthMs: number;
  phaseName: string;
  notStarted: boolean;
  phaseStartTime: number;
  phaseEndTime: number;
  phaseNumber: number;
  msToTime: (ms: number, withoutHours: boolean) => string | undefined;
  onChangeInputPhaseLength: (minutes: number, phase: number) => void;
  phaseMinutes: number;
  method: string;
  onChangeMethod: (method: string, phase: number) => void;
  editable: boolean;
  phase: number;
}

export const Phase: React.FunctionComponent<Props> = ({
  currentPhase,
  timeLeft,
  phaseLengthMs,
  phaseName,
  notStarted,
  phaseStartTime,
  phaseEndTime,
  phaseNumber,
  msToTime,
  onChangeInputPhaseLength,
  phaseMinutes,
  method,
  onChangeMethod,
  editable,
  phase,
}) => {
  if (currentPhase === undefined) {
    currentPhase = 0;
  }

  useEffect(() => {
    console.log("Phase: ", currentPhase);
  }, [currentPhase]);

  return (
    <div
      className={
        "mb-2 sm:mb-4 rounded overflow-hidden text-sm sm:text-base " +
        (currentPhase >= phaseNumber
          ? "border-4 border-green-600"
          : "border-4 border-gray-400")
      }
    >
      <div className="w-full h-0">
        <p className="text-center font-extrabold sm:-translate-y-0.5">
          {" "}
          {phaseName}{" "}
        </p>
      </div>
      <div
        className="flex bg-green-600 h-5"
        style={{
          width: 100 - Math.round((timeLeft / phaseLengthMs) * 100) + "%",
        }}
      ></div>
      <div className="bg-gray-400 p-2">
        <div className="grid grid-cols-3 h-5">
          <p className="font-bold">Methode:</p>
          <span className="text-center w-full">
            {editable ? (
              <input
                value={method}
                onChange={(event) => onChangeMethod(event.target.value, phase)}
                className="text-center rounded-md w-full"
              />
            ) : (
              <p>{method}</p>
            )}
          </span>
          <div className="justify-self-end">
            {editable ? (
              <input
                className="inline-flex w-10 rounded "
                type="number"
                value={phaseMinutes}
                onChange={(event) => {
                  onChangeInputPhaseLength(event.target.valueAsNumber, phase);
                }}
              />
            ) : (
              <>
                <span
                  className={
                    currentPhase === phaseNumber
                      ? "font-bold text-indigo-800"
                      : ""
                  }
                >
                  {notStarted ? "00:00" : msToTime(timeLeft, true)}
                </span>
                {" / "}
                {phaseMinutes}:00
              </>
            )}
          </div>
        </div>
        <div className="flex place-content-between">
          <p className="overflow-hidden">
            <strong>Von: </strong>
            {notStarted
              ? "00:00"
              : new Date(phaseStartTime).toLocaleTimeString().slice(0, -3)}{" "}
          </p>
          <p className="inline">
            <strong>Bis: </strong>
            {notStarted
              ? "00:00"
              : new Date(phaseEndTime).toLocaleTimeString().slice(0, -3)}
          </p>
        </div>
      </div>
    </div>
  );
};
